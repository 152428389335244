import React, { useState } from 'react'

import { merge } from 'lodash'

const AppSettings = {
    view: 0,
}
const AppContext = React.createContext({
    appSettings: AppSettings,
    updateAppSettings: () => {},
})

export const AppSettingsProvider = ({ appSettings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        appSettings || AppSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <AppContext.Provider
            value={{
                appSettings: currentSettings,
                updateAppSettings: handleUpdateSettings,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
export default AppContext
