import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        /* font-family: 'Titillium Web', sans-serif; */
        font-family: ${props => props.theme.fonts[0]}, ${props => props.theme.fonts[1]};
        accent-color: ${props => props.theme.colors.primaryColor};
    }
    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin:0px;
        color: ${props => props.theme.colors.headingColors}
    }
    p{
        margin-top:0;
        font-size:16px;
        color: ${props => props.theme.colors.paragraphColors}
    }
`;

export default GlobalStyles