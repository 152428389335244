import clsx from 'clsx'
import { useEffect } from 'react'
import { styled, Box } from '@mui/system'
import { useLocation } from 'react-router-dom'
import { Icon, ButtonBase } from '@mui/material'
import React, { useState, useRef, useCallback } from 'react'

const NavExpandRoot = styled('div')(({ theme }) => ({
    fontFamily: "Titillium Web",
    borderRadius: '14px',
    width: 200,
    marginBottom: '10px',
    '& .expandIcon': {
        transition: 'transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'rotate(270deg)',
    },
    "&.active": {
        // backgroundColor: "#E9E9E9",
        color: "#475569",
        backgroundColor: "#E9E9E9",
    },
    
    '& .collapseIcon': {
        transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'rotate(90deg)',
    },
    '& .expansion-panel': {
        //rozwijana lista
        overflow: 'hidden',
        transition: 'max-height 0.3s cubic-bezier(0, 0, 0.2, 1)',
        backgroundColor: "#E9E9E9",
        borderRadius: "0 0 12px 12px",
        '& .navItemActive': {
            backgroundColor: "#E9E9E9",
            color: "#475569",
            "& span":{
                fontWeight: "600",
            }
        },
        "& :hover":{
            // backgroundColor: "#CCCCCC", // Mniej nasycenie, bardziej szary kolor
            // color: "#475569",
        }
    },
    '& .highlight': {
        background: theme.palette.primary.main,
    },
    '&.compactNavItem': {
        width: 44,
        overflow: 'hidden',
        justifyContent: 'center !important',
        '& .itemText': {
            display: 'none',
        },
        '& .itemIcon': {
            display: 'none',
        },
    },
}))

const BaseButton = styled(ButtonBase)(({ theme }) => ({
    //Przycisk rozwijany -> configuration, Statistics
    // background: "red",
    paddingLeft: "15px",
    height: 50,
    width: 200,    
    borderRadius: '14px',
    whiteSpace: 'pre',
    overflow: 'hidden',
    margin: '0 10px 8px 0', 
    // marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between !important',
    color: "#475569",
    "&.active": {
        // border: "1px solid pink"
        color:"#fff",
        backgroundColor:"#7d53de",
    },
    // color: theme.palette.text.primary,
    // background: "yellow",
    '&:hover': {
        // background: 'rgba(255, 255, 255, 0.08)',
    },
    //Ikonka po lewej stronie napisu
    '& .icon': {
        // background: "green",
        width: 36,
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle',
    },
}))

const BulletIcon = styled('div')(({ theme }) => ({
    width: 4,
    height: 4,
    color: 'inherit',
    overflow: 'hidden',
    marginLeft: '20px',
    marginRight: '8px',
    borderRadius: '300px !important',
    
   // background: theme.palette.primary.contrastText,
    // background: theme.palette.text.primary,
}))

const ItemText = styled('span')(() => ({
    fontFamily: "Titillium Web",
    fontSize: '16px',
    paddingLeft: '0.8rem',
    verticalAlign: 'middle',
   
}))

const BadgeValue = styled('div')(() => ({
    padding: '1px 4px',
    overflow: 'hidden',
    borderRadius: '300px',
}))

const Img = styled('img')(()=>({
    "&.active":{
        filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%)"
    }
}))

const MatxVerticalNavExpansionPanel = ({ item, children, mode }) => {
    const [collapsed, setCollapsed] = useState(true)
    const elementRef = useRef(null)
    const componentHeight = useRef(0)
    const { pathname } = useLocation()
    const { name, icon, iconText, badge } = item;
    const [style, setStyle] = useState(false);

    const handleClick = () => {
        componentHeight.current = 0
        calcaulateHeight(elementRef.current)
        setCollapsed(!collapsed)
    }

    const calcaulateHeight = useCallback((node) => {
        if (node.name !== 'child') {
            for (let child of node.children) {
                calcaulateHeight(child)
            }
        }

        if (node.name === 'child') componentHeight.current += node.scrollHeight
        else componentHeight.current += 44 //here 44 is node height
        return
    }, [])

    useEffect(() => {
        if (!elementRef) return

        calcaulateHeight(elementRef.current)

        // OPEN DROPDOWN IF CHILD IS ACTIVE
        for (let child of elementRef.current.children) {
            if (child.getAttribute('href') === pathname) {
                setCollapsed(false)
            }
        }
    }, [pathname, calcaulateHeight])

    const location = useLocation();

//   const isActive = false;

    const isActive = children.find(path =>path.props.children.props.to === location.pathname)

    // console.log(location.pathname)

    // children.forEach(path => console.log(path.props.children.props.to === location))

    console.log(isActive)

    return (
        <NavExpandRoot className={clsx({
            sidenavHoverShow: true,
            active: !collapsed,
        })} >
            <BaseButton
                className={[
                    isActive ? "active" : "",
                    clsx({
                    'has-submenu compactNavItem': true,
                    compactNavItem: mode === 'compact',
                    open: !collapsed,
                    active: !collapsed,
                })].join(" ")}
                onClick={ ()=> {
                    handleClick();
                    setStyle(!style);
                }}
            >
                <Box display="flex" alignItems="center">
                    {/* {icon && <Icon className="icon">{icon}</Icon>} */}
                    {icon && 
                    <Img src={require(`../../views/Keno/Icons/${icon}.svg`).default} 
                    alt={item.icon} 
                    className={clsx({active: !collapsed})}
                    />}
                    {iconText && <BulletIcon />}
                    <ItemText className={["sidenavHoverShow", isActive ? "active" : ""].join(" ")}>{name}</ItemText>
                </Box>
                {badge && (
                    <BadgeValue className="sidenavHoverShow itemIcon" >
                        {badge.value}
                    </BadgeValue>
                )}
                <div
                    className={clsx({
                        sidenavHoverShow: true,
                        collapseIcon: collapsed,
                        expandIcon: !collapsed,
                    })}
                >
                    <Icon fontSize="small" sx={{ verticalAlign: 'middle' }}>
                        chevron_right
                    </Icon>
                </div>
            </BaseButton>
            <div
                ref={elementRef}
                className="expansion-panel submenu"
                style={
                    collapsed
                    ? { maxHeight: '0px' }
                    : { maxHeight: componentHeight.current + 'px' }
                }
                >
                {children}
            </div>
        </NavExpandRoot>
    )
}

export default MatxVerticalNavExpansionPanel
