import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

export const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    users: null,
}

const URL = '/db/userList';


const isValidToken = (accessToken) => {

    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000


    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, users } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                users,
            }
        }
        case 'LOGIN': {
            const { user, users } = action.payload
            return {
                ...state,
                isInitialised: true,
                isAuthenticated: true,
                user,
                users
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isInitialised: true,
                isAuthenticated: true,
                user,
            }
        }
        case 'ADD_USER': {
            const { users } = action.payload
            return {
                ...state,
                users
            }
        }

        case 'EDIT_USER': {
            const { user, users } = action.payload
            return {
                ...state,
                user,
                users
            }
        }

        case 'DELETE_USER': {
            const { users } = action.payload
            return {
                ...state,
                users
            }
        }

        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    loginWithoutMock: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    addUser: () => Promise.resolve(),
    editUser: () => Promise.resolve(),
    deleteUser: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)


    const addUser = async (name, surname, jobPosition, email, role, password, admin) => {

        let users;
        console.log(name, surname, jobPosition, email, role, password, admin);

        try {
            await axios({
                method: 'POST',
                url: URL,
                data: {
                    name,
                    surname,
                    jobPosition,
                    email,
                    role,
                    password,
                    admin,
                },
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

            users = await axios({
                method: 'GET',
                url: URL,
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })


        } catch (error) {
            console.error("Wystąpił problem z aktaulizacją zasobu, skontatkuj się z administratorem")
        }



        dispatch({
            type: "ADD_USER",
            payload: {
                users: users.data
            }
        })

    }

    const resetPassword = async (_id, currentUserId, newPassword) => {


        let newURL = `${URL}/${_id}`;

        try {
            console.log(newURL);
            await axios({
                method: 'PATCH',
                url: newURL,
                data: {
                    "password": newPassword
                },
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

        } catch (error) {
            console.error("Wystąpił problem z resetowaniem hasła, skontatkuj się z administratorem")
        }


        if (_id === currentUserId) {
            logout()
        }

    }

    const editUser = async (currentUserId, _id, name, surname, email, jobPosition, admin) => {
        let user, users;

        let newURL = `${URL}/${_id}`;

        console.log(currentUserId, _id, name, surname, email, jobPosition, admin);

        try {
            await axios({
                method: 'PATCH',
                url: newURL,
                data: {
                    "name": name,
                    "surname": surname,
                    "email": email,
                    "jobPosition": jobPosition,
                    "admin": admin
                },
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

            users = await axios({
                url: URL, auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })


            user = users.data.filter(userList => userList._id.$oid == currentUserId);

        } catch (error) {
            console.error("Wystąpił problem z aktaulizacją zasobu, skontatkuj się z administratorem")
        }




        dispatch({
            type: "EDIT_USER",
            payload: {
                user: user[0],
                users: users.data
            }
        })
    }

    const deleteUser = async (_id, currentUserId) => {
        let users;

        let newURL = `${URL}/${_id}`;

        try {
            await axios({
                method: 'DELETE',
                url: newURL,
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

            users = await axios({
                method: 'GET',
                url: URL,
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

        } catch (error) {
            console.error("Wystąpił problem z aktaulizacją zasobu, skontatkuj się z administratorem")
        }



        dispatch({
            type: 'DELETE_USER',
            payload: {
                users: users.data
            }
        })

        if (_id === currentUserId) {
            logout()
        }
    }

    const login = async (email, password) => {

        const response = await axios({
            method: 'GET',
            url: `${URL}/?filter={"email":"${email}", "password":"${password}"}`,
            auth: {
                username: process.env.REACT_APP_USER_NAME,
                password: process.env.REACT_APP_PASSWORD
            }
        })

        console.log(response.data[0]);

        const user = response.data[0];


        setSession(user._id.$oid)


        const users = user.admin
            ? await axios({
                url: URL,
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD,
                },
            })
            : [];

        console.log(users);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
                users: users.data
            },
        })


    }


    const register = async (email, name, surname, password) => {
        let response;
        try {
            await axios({
                method: 'POST',
                url: URL,
                data: {
                    email,
                    name,
                    surname,
                    password,
                    jobPosition: "Użytkownik",
                    role: 'SA',
                    age: 30
                },
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })

            response = await axios({
                method: 'GET',
                url: `${URL}/?filter={"email":"${email}", "password":"${password}"}`,
                auth: {
                    username: process.env.REACT_APP_USER_NAME,
                    password: process.env.REACT_APP_PASSWORD
                }
            })



        } catch (error) {
            console.error("Wystąpił problem z zarejestrowaniem nowego użytkownika. Skontatkuj się z administratorem")
        }


        console.log(response.data[0]);

        const user = response.data[0];


        setSession(user._id.$oid)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                if (accessToken) {
                    setSession(accessToken)
                    const response = await axios({
                        url: `${URL}/${accessToken}`,
                        auth: {
                            username: process.env.REACT_APP_USER_NAME,
                            password: process.env.REACT_APP_PASSWORD
                        }
                    });
                    const users = await axios({
                        url: URL,
                        auth: {
                            username: process.env.REACT_APP_USER_NAME,
                            password: process.env.REACT_APP_PASSWORD
                        }
                    });
                    const user = response.data
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                            users: users.data
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                editUser,
                addUser,
                deleteUser,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
