import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'


const AddEmployee = Loadable(lazy(() => import("./AddEmployee/AddEmployee")));
const EmployeeMe = Loadable(lazy(() => import("./EmployeeMe/EmployeeMe")));
const EmployeeOther = Loadable(lazy(() => import("./EmployeeOther/EmployeeOther")));
const Employees = Loadable(lazy(() => import("./Employees/Employees")));

const usersRoute = [
    {
        path: '/profil',
        element: <EmployeeMe />,
    },
    {
        path: '/pracownicy/:id',
        element: <EmployeeOther />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/pracownicy',
        element: <Employees />,
        auth: authRoles.forUserSettings,

    },
    {
        path: '/dodaj_pracownika',
        element: <AddEmployee />,
        auth: authRoles.forUserSettings,
    },
]

export default usersRoute;
