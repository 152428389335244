import {
    GET_PARAMS,
} from '../actions/ParametersActions'

const initialState = []

const ParametersReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PARAMS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return {...state}
        }
    }
}

export default ParametersReducer
