import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const DayReport = Loadable(lazy(() => import("./day/DayReport")));
const WeekReport = Loadable(lazy(() => import("./week/WeekReport")));

const reportsRoute = [
    {
        path: '/dzienne',
        element: <DayReport />,
    },
    {
        path: '/tygodniowe',
        element: <WeekReport />,
    },
]

export default reportsRoute
