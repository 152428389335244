// import Mock from '../mock'

// const paramsDB = [
//     {
//         "name": "Linia do wytwarzania surowego mięsa świeżego",
//         "icon": "/assets/images/meat/getty_80116649_344560.jpg",
//         "devices": [
//             {
//                 "name": "Chłodnia",
//                 "description": "Wydajność do 2200kg/h; czas cyklu od 6 do 20 min",
//                 "path": "/wykresy/0",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Temperatura wyjściowa surowca",
//                         "unit": "°C",
//                         "status": 2,
//                         "db_path": "bbf2200_out_temperature",
//                         "db_params": { "filter": { "payload.temperature": { "$lt": -7, "$gt": -12 } }, "pagesize": 1000 },
//                         "obj_path": "payload.temperature",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Temperatura wejściowa",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "bbf2200_in_temperature",
//                         "db_params": { "filter": { "payload.temperature": { "$lt": 0 } }, "pagesize": 1000 },
//                         "obj_path": "payload.temperature",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Maszyna do porcjowania mięsa",
//                 "description": "Wydajność od 1200 do 1700 kg/h, porcjowanie jednocześnie 2 kawałków produktu",
//                 "path": "/wykresy/1",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Wydajność",
//                         "unit": "szt./min.",
//                         "status": 1,
//                         "db_path": "porcjowanie60",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Waga dynamiczna",
//                 "description": "Regulowana dokładność ok. +/- 1 g; regulowana prędkość taśm w zakresie 0 ÷ 1,2 m/s",
//                 "path": "/wykresy/2",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Średnia masa",
//                         "unit": "g",
//                         "status": 1,
//                         "opts": { "yAxis": { "min": 480, "max": 520 } },
//                         "opts": { "yAxis": { "min": 480, "max" : 520 }},
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Average",
//                         "db_view_param": "averageMass",
//                     },
//                     {
//                         "name": "Prawidłowych",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Individuals.Random_Sample.Production_Data.1.Sum_Number.0",
//                     },
//                     {
//                         "name": "Nieprawidłowych",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Individuals.Random_Sample.Rejected_Packages.0.Data_Cell.0.Sum_Number.0",
//                     },
//                 ],
//             },
//             {
//                 "name": "Traysealer",
//                 "description": "15-17 taktów / min (68 do 51 tacek/min w zależności od rozmiaru opakowania) dla opakowań z atmosferą ochronną wysokotlenową i próżnią końcową 200 mbar; obsługiwane wymiary tacek: 187x137 mm, głębokość od 12 do 120 mm; 205x160, głębokość od 12 do 120 mm; 227x179 mm, głębokość od 12 do 120 mm, tacki wykonane z foli twardej",
//                 "path": "/wykresy/3",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Wydajność",
//                         "unit": "szt./min.",
//                         "status": 1,
//                         "db_path": "tx710_counter",
//                         "db_params": { "filter": { "payload.value": { "$gt": 0 } }, "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Automat ważąco-etykietujący",
//                 "description": "Wydajność do 100 opakowań/min",
//                 "path": "/wykresy/4",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Średnia masa w jednostce czasu",
//                         "unit": "kg",
//                         "status": 1,
//                         "db_path": "bizerba",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.ActualNetWeightValue",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "X-Ray",
//                 "description": "",
//                 "path": "/wykresy/5",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Odrzuty",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.3",
//                         "db_view_param": "3",
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         "name": "Linia do wytwarzania surowej białej kiełbasy",
//         "icon": "/assets/images/meat/2000000246833.jpg",
//         "devices": [
//             {
//                 "name": "Nadziewarka",
//                 "description": "Wydajność porcjowania do 1000 porcji/min, wydajność pompy do 9000 kg/h",
//                 "path": "/wykresy/0",
//                 "parameters": [
//                     {
//                         "name": "Wydajność",
//                         "unit": "kg/min.",
//                         "status": 1,
//                         "db_path": "vf838s_speed",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Ilość opakowań w jednostce czasu",
//                         "unit": "szt./min.",
//                         "status": 1,
//                         "db_path": "vf838s_portions",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Temperatura farszu",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "vf838s_temp",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Mieszałka",
//                 "description": "Maksymalny wsad 900 l, pojemność całkowita 1500 l",
//                 "path": "/wykresy/1",
//                 "parameters": [
//                     {
//                         "name": "Temperatura na starcie",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "mieszalka_lopatkowa_temperatura",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Temperatura po procesie",
//                         "unit": "°C",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Czas mieszania",
//                         "unit": "min.",
//                         "status": 1,
//                         "db_path": "mieszalka_lopatkowa_czas",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Waga dynamiczna",
//                 "description": "Regulowana dokładność ok. +/- 1 g; regulowana prędkość taśm w zakresie 0 ÷ 1,2 m/s",
//                 "path": "/wykresy/2",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Średnia masa",
//                         "unit": "g",
//                         "status": 1,
//                         "opts": { "yAxis": { "min": 480, "max": 520 } },
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Average",
//                         "db_view_param": "averageMass",
//                     },
//                     {
//                         "name": "Prawidłowych",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Individuals.Random_Sample.Production_Data.1.Sum_Number.0",
//                     },
//                     {
//                         "name": "Nieprawidłowych",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "minebea",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.Individuals.Random_Sample.Rejected_Packages.0.Data_Cell.0.Sum_Number.0",
//                     },
//                 ],
//             },
//             {
//                 "name": "Automat ważąco-etykietujący",
//                 "description": "Wydajność do 100 opakowań/min",
//                 "path": "/wykresy/3",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Średnia masa w jednostce czasu",
//                         "unit": "kg",
//                         "status": 1,
//                         "db_path": "bizerba",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.ActualNetWeightValue",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "MeatMaster",
//                 "description": "Moc lampy rentgenowskiej: 50kV, prędkość taśmy: 0.32-1,04 m/sec",
//                 "path": "/wykresy/4",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Zawartość tłuszczu",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.3",
//                         "db_view_param": "3",
//                     },
//                     {
//                         "name": "Waga",
//                         "unit": "kg",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.2",
//                         "db_view_param": "2",
//                     },
//                     {
//                         "name": "Metal",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.4",
//                         "db_view_param": "4",
//                     },
//                     {
//                         "name": "Kość",
//                         "unit": "szt.",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.5",
//                         "db_view_param": "5",
//                     },
//                 ],
//             },
//             {
//                 "name": "X-Ray",
//                 "description": "",
//                 "path": "/wykresy/5",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Odrzuty",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.3",
//                         "db_view_param": "3",
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         "name": "Linia do wytwarzania surowych wyrobów marynowanych/grillowych",
//         "icon": "/assets/images/meat/marynaty-r1.jpg",
//         "devices": [
//             {
//                 "name": "Nastrzykiwarka",
//                 "description": "Wydajność do 6000 kg/h",
//                 "path": "/wykresy/0",
//                 "parameters": [
//                     {
//                         "name": "Przesuw taśmy",
//                         "unit": "szt./min.",
//                         "status": 1,
//                         "db_path": "mh212_speed",
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Wydajność",
//                         "unit": "kg/h",
//                         "status": 1,
//                         "db_path": "mh212_injection",
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Masownica 700l",
//                 "description": "Pojemność 700l",
//                 "path": "/wykresy/1",
//                 "parameters": [
//                     {
//                         "name": "Temperatura",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "masownica_700l",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Masownica 1000l",
//                 "description": "Pojemność 1000l",
//                 "path": "/wykresy/2",
//                 "parameters": [
//                     {
//                         "name": "Temperatura",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "masownica_1000l",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Maszyna pakująca",
//                 "description": "Wydajność 1800kg/h",
//                 "path": "/wykresy/3",
//                 "parameters": [
//                     {
//                         "name": "Wydajność",
//                         "unit": "szt./min.",
//                         "status": 1,
//                         "db_path": "rolowa_current_speed",
//                         "db_params": { "filter": { "payload.value": { "$gt": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.value",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Mikrofala",
//                 "description": "Wydajność rozmrażania od 1800 kg do 7000 kg/godz",
//                 "path": "/wykresy/4",
//                 "parameters": [
//                     {
//                         "name": "Czas procesu",
//                         "unit": "min.",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Moc mikrofal",
//                         "unit": "kW",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Temperatura na starcie",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "mikrofala_in_temperature",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.temperature",
//                         "db_view_param": "average",
//                     },
//                     {
//                         "name": "Temperatura po procesie",
//                         "unit": "°C",
//                         "status": 1,
//                         "db_path": "mikrofala_out_temperature",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.temperature",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Maszyna do porcjowania mięsa",
//                 "description": "Wydajność od 1200 do 1700 kg/h, porcjowanie jednocześnie 2 kawałków produktu",
//                 "path": "/wykresy/5",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Wydajność",
//                         "unit": "szt./min.",
//                         "status": 1,
//                     },
//                 ],
//             },
//             {
//                 "name": "Automat ważąco-etykietujący",
//                 "description": "Wydajność do 100 opakowań/min",
//                 "path": "/wykresy/6",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Średnia masa w jednostce czasu",
//                         "unit": "kg",
//                         "status": 1,
//                         "db_path": "bizerba",
//                         "db_params": { "pagesize": 1000 },
//                         "obj_path": "payload.value.ActualNetWeightValue",
//                         "db_view_param": "average",
//                     },
//                 ],
//             },
//             {
//                 "name": "X-Ray",
//                 "description": "",
//                 "path": "/wykresy/7",
//                 "iconText": "404",
//                 "parameters": [
//                     {
//                         "name": "Odrzuty",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "xray_data",
//                         "db_params": { "filter": { "payload.type": { "$eq": 1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.params.3",
//                         "db_view_param": "3",
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         "name": "Urządzenia dodatkowe/wspomagające",
//         "devices": [
//             {
//                 "name": "Analizator gazów",
//                 "description": "Dokładność pomiaru: O2 - .0,1% w zakresie pomiarowym 0 – 10%, .1% w zakresie pomiarowym 0 –100%; CO2: .2% pełnego zakresu pomiarowego",
//                 "path": "/wykresy/0",
//                 "parameters": [
//                     {
//                         "name": "Ilość gazu CO2",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "analizator_map_check_result",
//                         "db_params": { "filter": { "payload.val": { "$gt": 0.1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.val",
//                         "db_view_param": "val_average",
//                     },
//                     {
//                         "name": "Ilość gazu O2",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "analizator_map_check_result",
//                         "db_params": { "filter": { "payload.o2": { "$gt": 0.1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.o2",
//                         "db_view_param": "o2_average",
//                     },
//                     {
//                         "name": "Ilość pozostałych gazów",
//                         "unit": "%",
//                         "status": 1,
//                         "db_path": "analizator_map_check_result",
//                         "db_params": { "filter": { "payload.inne": { "$gt": 0.1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.inne",
//                         "db_view_param": "inne_average",
//                     },
//                     {
//                         "name": "Ciśnienie",
//                         "unit": "bar",
//                         "status": 1,
//                         "db_path": "analizator_map_check_result",
//                         "db_params": { "filter": { "payload.cisnienie": { "$gt": 0.1 } }, "pagesize": 1000 },
//                         "obj_path": "payload.cisnienie",
//                         "db_view_param": "cisnienie_average",
//                     },
//                 ],
//             },
//             {
//                 "name": "Pulsujące pole magnetyczne",
//                 "description": "Maksymalne natężenia pola do 6 – 10 Tesli, system impulsowania pola magnetycznego: impulsu o czasie trwania 1 - 20 msek. (z regulacją)",
//                 "path": "/wykresy/1",
//                 "parameters": [
//                     {
//                         "name": "Czas procesu",
//                         "unit": "min.",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Temperatura próbki",
//                         "unit": "°C",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Natężenie pola",
//                         "unit": "T",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Czas impulsu",
//                         "unit": "msek.",
//                         "status": 1,
//                     },
//                 ],
//             },
//             {
//                 "name": "Plazma koronowa/barierowa",
//                 "description": "Zasilanie impulsami o regulowanym czasie trwania (1 – 20 msek) i amplitudzie (do 40 kV), prowadzenie procesu w ciśnieniu atmosferycznym lub w obniżonym ciśnieniu (redukcja ciśnienia w zakresie 1 – 30 hPa)",
//                 "path": "/wykresy/2",
//                 "parameters": [
//                     {
//                         "name": "Czas procesu",
//                         "unit": "min.",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Temperatura próbki",
//                         "unit": "°C",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Ciśnienie",
//                         "unit": "hPa",
//                         "status": 1,
//                     },
//                     {
//                         "name": "Czas impulsu",
//                         "unit": "msek.",
//                         "status": 1,
//                     },
//                 ],
//             },
//         ],
//     },
// ]

// Mock.onGet('/api/parameters').reply((config) => {
//     return [200, paramsDB]
// })
