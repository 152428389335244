import Mock from '../mock'

Mock.onGet('/api/data').reply((config) => {
    console.log(config)
    var elements = [];
    var date_start = new Date(config.start)
    var date_end = new Date(config.end)
    const diffTime = Math.abs(date_end - date_start)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    var date_cntr = new Date(config.start)
    for (let i = 0; i <= diffDays; i++) {
        let basic = {
            "data": date_cntr.toLocaleDateString(), //new Date("2022/03/" + ('0' + (i + 1)).slice(-2)),
            "czas": i % 24 + ":00:00"
        }
        var obj = {};
        for (let x = 0; x < config.params; x++) {
            obj[x] = Math.floor(Math.random() * 100);
        }
        elements.push(
            {
                ...basic,
                ...obj,
            })
        date_cntr.setDate(date_cntr.getDate() + 1)
    }
    return [200, elements]
})
