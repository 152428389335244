import '../fake-db'
import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { AppSettingsProvider } from 'app/contexts/AppContext'
import Theme from './views/Keno/Theme'
import GlobalStyles from './views/Keno/GlobalStyles'


const App = () => {
    const all_pages = useRoutes(AllPages())

    return (
        <Provider store={Store}>
            <Theme>
                <AppSettingsProvider>
                    <SettingsProvider>
                        <MatxTheme>
                            <GlobalStyles/>
                            <AuthProvider>{all_pages}</AuthProvider>
                        </MatxTheme>
                    </SettingsProvider>
                </AppSettingsProvider>
            </Theme>
        </Provider>
    )
}

export default App
