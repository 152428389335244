const initialState = []

const DataFetchReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'LOAD_SUCCESS': {
            delete state[state.findIndex(({ url }) => url === action.payload.config.url)]
            state = state.filter(function (el) { return el !== undefined})
            return [{url : action.payload.config.url, data: action.payload.data}, ...state]
        }
        case 'LOAD': {
            if (state.find(({ url }) => url === action.payload.request.url)) {
                delete state[state.findIndex(({ url }) => url === action.payload.request.url)]
                state = state.filter(function (el) { return el !== undefined})
            }
            return [{url : action.payload.request.url, loading: true}, ...state]
        }
        default: {
            return [...state]
        }
    }
}

export default DataFetchReducer
