
export const navigations = [
    // {
    //     name: 'Pulpit',
    //     path: '/dashboard',
    //     icon: 'dashboard',
    // },
    // {
    //     label: 'Raporty',
    //     type: 'label',
    // },
    // {
    //     name: 'Wykresy',
    //     icon: 'trending_up',

    //     children: [],
    //     dynamic: true,
    // },
    // {
    //     name: 'Zestawienia',
    //     icon: 'wrap_text',
    //     children: [
    //         {
    //             name: 'Dzienne',
    //             iconText: '404',
    //             path: '/dzienne',
    //         },
    //         {
    //             name: 'Tygodniowe',
    //             iconText: '404',
    //             path: '/tygodniowe',
    //         },
    //     ],
    // },
    // {
    //     label: 'Uzytkownicy',
    //     iconText: '404',
    //     type: 'label',
    // },
    // {
    //     name: 'Profil',
    //     icon: 'person',
    //     path: '/profil',
    // },
    // {
    //     name: 'Pracownicy',
    //     icon: 'people',
    //     path: '/pracownicy',
    // },
    // {
    //     name: 'Dodaj pracownika',
    //     icon: 'person_add',
    //     path: '/dodaj_pracownika',
    // },
    // {
    //     label: 'Produkt',
    //     type: 'label',
    // },
    // {
    //     name: 'MeatMaster',
    //     icon: 'satellite',
    //     path: '/produkt',
    // },
    // {
    //     name: 'X-Ray',
    //     icon: 'satellite',
    //     path: '/produktxray',
    // },
    {
        name: 'Dashboard',
        icon: 'dashboard',
        path: '/dashboard',
    },
    {
        name: 'Configuration',
        icon: 'configuration',
        children: [
            {
                name: 'Temperature Sensors',
                iconText: '404',
                path: '/temperature_sensors',
            },
            {
                name: 'Outputs',
                iconText: '404',
                path: '/outputs',
            },
            {
                name: 'Source Devices',
                iconText: '404',
                path: '/source_devices',
            },
            {
                name: 'Receiving Devices',
                iconText: '404',
                path: '/receiving_devices',
            },
            {
                name: 'Tanks',
                iconText: '404',
                path: '/tanks',
            },
            {
                name: 'Scheduler',
                iconText: '404',
                path: '/scheduler',
            },
        ],
    },
    // {
    //     name: 'Statisticsdada',
    //     icon: 'statistics',
    //     children: [
    //         {
    //             name: 'Dzienne',
    //             iconText: '404',
    //             path: '/dzienne',
    //         },
    //         {
    //             name: 'Tygodniowe',
    //             iconText: '404',
    //             path: '/tygodniowe',
    //         },
    //     ],
    // },
    {
        name: 'Statistics',
        icon: 'statistics',
        children: [
            {
                name: 'Line',
                iconText: '404',
                path: '/line',
            },
            {
                name: 'Pie',
                iconText: '404',
                path: '/pie',
            },
        ],
    },
    {
        name: 'Savings',
        icon: 'savings',
        path: '/savings',
    },
    {
        name: 'Access request',
        icon: 'accessRequest',
        path: '/access_request',
    },
    {
        name: 'Help',
        icon: 'help',
        path: '/help',
    },
    {
        name: 'Settings',
        icon: 'settings',
        path: '/settings',
    },
    {
        name: 'Logout',
        icon: 'logout',
        path: '/logout',
    },
]
