import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'


const Dashboard = Loadable(lazy(() => import("./Dashboard/Dashboard")));
//ConfigurationViews
const TemperatureSensors = Loadable(lazy(() => import("./ConfigurationViews/TemperatureSensors/TemperatureSensors")));
const Outputs = Loadable(lazy(() => import("./ConfigurationViews/Outputs/Outputs")));
const SourceDevices = Loadable(lazy(() => import("./ConfigurationViews/SourceDevices/SourceDevices")));
const ReceivingDevices = Loadable(lazy(() => import("./ConfigurationViews/ReceivingDevices/ReceivingDevices")));
const Tanks = Loadable(lazy(() => import("./ConfigurationViews/Tanks/Tanks")));
const Scheduler = Loadable(lazy(() => import("./ConfigurationViews/Scheduler/Scheduler")));
//Statistics
const ChartLine = Loadable(lazy(() => import("./Statistics/ChartLine")));
const ChartPie = Loadable(lazy(() => import("./Statistics/ChartPie")));
//
const Savings = Loadable(lazy(() => import("./Savings/Savings")));
const AccessRequest = Loadable(lazy(() => import("./AccessRequest/AccessRequest")));
const Help = Loadable(lazy(() => import("./Help/Help")));
const SettingsView = Loadable(lazy(() => import("./SettingsView/SettingsView")));
const Logout = Loadable(lazy(() => import("./Logout/Logout")));

const KenoRoute = [
    //  Configuration
    //  start
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/temperature_sensors',
        element: <TemperatureSensors />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/outputs',
        element: <Outputs />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/source_devices',
        element: <SourceDevices />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/receiving_devices',
        element: <ReceivingDevices />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/tanks',
        element: <Tanks />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/scheduler',
        element: <Scheduler />,
        auth: authRoles.forUserSettings,
    },
    //  end
    //Statistics
    //start
    {
        path: '/line',
        element: <ChartLine />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/pie',
        element: <ChartPie />,
        auth: authRoles.forUserSettings,
    },
    //end
    {
        path: '/savings',
        element: <Savings />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/access_request',
        element: <AccessRequest />,
        auth: authRoles.forUserSettings,

    },
    {
        path: '/help',
        element: <Help />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/settings',
        element: <SettingsView />,
        auth: authRoles.forUserSettings,
    },
    {
        path: '/logout',
        element: <Logout />,
        auth: authRoles.forUserSettings,
    },
]

export default KenoRoute;
