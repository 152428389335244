import React from 'react'
import App from './app/App'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import * as serviceWorker from './serviceWorker'
import { StyledEngineProvider } from '@mui/styled-engine'
import { CssBaseline } from '@mui/material'

import global_en from "./translations/en/global.json";
import global_pl from "./translations/pl/global.json";

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next'

i18next.init({
    //Mitigate xss attacks
    interpolation: {excapevalue: false},
    lng: "en",
    resources: {
        en: {
            global: global_en
        },
        pl: {
            global: global_pl
        },
    }
}
)

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18next}>
            <BrowserRouter>
                <CssBaseline />
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </StyledEngineProvider>,
    document.getElementById('root')
)

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
