import Mock from '../mock'
import jwt from 'jsonwebtoken'

const JWT_SECRET = 'jwt_secret_key'
const JWT_VALIDITY = '7 days'
const userList = [
    {
        _id: 1,
        name: 'Jan',
        surname: 'Kowalski',
        jobPosition: "kierowniki",
        role: 'SA',
        admin: true,
        email: 'jkowalski@pdmlab.pl',
        password: 'a12345',
        age: 25,
    },
    {
        id: 2,
        name: 'Denis',
        surname: 'Bogusiewicz',
        role: 'SA',
        admin: false,
        password: 'a1234',
        email: 'denis.bogusiewicz@gmail.com',
        age: 25,
    },
    {
        id: 3,
        name: 'Daria',
        surname: 'Bogusiewicz',
        role: 'SA',
        admin: false,
        password: 'a12345',
        email: 'daria.bogusiewicz@gmail.com',
        age: 25,
    },
    {
        id: 3,
        name: 'User3',
        surname: 'Smith3',
        role: 'User',
        admin: true,
        email: 'user3@example.com',
        password: 'dadada',
        age: 30,
    },
    {
        id: 4,
        name: 'User4',
        surname: 'Smith4',
        role: 'User',
        admin: false,
        email: 'user4@example.com',
        password: 'gaaca',
        age: 30,
    },
    {
        id: 5,
        name: 'User5',
        surname: 'Smith5',
        role: 'User',
        admin: false,
        email: 'user5@example.com',
        password: 'ad123',
        age: 30,
    },
    {
        id: 6,
        name: 'User6',
        surname: 'Smith6',
        role: 'User',
        admin: true,
        email: 'user6@example.com',
        password: 'dad21313',
        age: 30,
    },
    {
        id: 7,
        name: 'User7',
        role: 'User',
        surname: 'Smith7',
        admin: true,
        email: 'user7@example.com',
        password: 'da13fv',
        age: 30,
    },
    {
        id: 8,
        name: 'User8',
        role: 'SA',
        surname: 'Smith8',
        admin: true,
        email: 'user8@example.com',
        password: 'da13fv',
        age: 30,
    },
    {
        id: 9,
        name: 'User9',
        role: 'User',
        surname: 'Smith9',
        admin: true,
        email: 'user9@example.com',
        password: 'da13fv',
        age: 30,
    },
]
// FOLLOWING CODES ARE MOCK SERVER IMPLEMENTATION
// YOU NEED TO BUILD YOUR OWN SERVER
// IF YOU NEED HELP ABOUT SERVER SIDE IMPLEMENTATION
// CONTACT US AT support@ui-lib.com



Mock.onPost('/api/auth/addUser').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))


        const { name, surname, jobPosition, email, role, password } = JSON.parse(config.data);


        const lastElement = userList.slice(-1);

        const newId = lastElement[0]._id + 1;


        const newUser = {
            _id: newId,
            name,
            surname,
            jobPosition,
            role,
            email,
            password
        }


        userList.push(newUser)


        console.log(userList);

        return [200, { users: userList }];

    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server errorsss' }];
    }


});

Mock.onPost('/api/auth/deleteUser').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))


        const { _id, currentUserId } = JSON.parse(config.data);

        const currentUser = _id === currentUserId

        const newArray = userList.filter(user => user._id !== _id)

        userList = newArray;

        return [200, { users: userList, currentUser }];

    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server errorsss' }];
    }


});


Mock.onPost('/api/auth/resetPassword').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const { currentUserId, _id, newPassword } = JSON.parse(config.data)

        const user = userList.find((u) => {
            return (u._id === _id)
        })



        if (!user) {
            return [400, { message: 'Coś poszło nie tak... użytkownik najprawdopodobniej nie istnieje' }]
        }

        const newUser = {
            ...user,
            password: newPassword
        }

        console.log(newUser);

        userList = userList.map(user => user._id === _id ? newUser : user);


        console.log(userList);



        if (currentUserId === _id) {
            return [200, {
                users: userList,
                currentUser: true,
            }]

        }
        else {
            return [200, {
                users: userList,
                currentUser: false,
            }]
        }


    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/auth/editUser').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const { _id, currentUserId, name, surname, email, jobPosition } = JSON.parse(config.data)

        console.log(JSON.parse(config.data));

        const user = userList.find((u) => {
            console.log(u)
            console.log(_id)
            return (u._id === _id)
        })

        if (!user) {
            return [400, { message: 'Coś poszło nie tak... użytkownik najprawdopodobniej nie istnieje' }]
        }

        const newUser = {
            ...user,
            name,
            surname,
            email,
            jobPosition
        }



        userList = userList.map(user => user._id === _id ? newUser : user)

        const currentUser = userList.find(currentUser => currentUser._id === currentUserId)

        console.log(user);

        if (currentUser._id === _id) {
            return [200, {
                user: newUser,
                users: userList,
            }]

        }
        else {
            return [200, {
                user: currentUser,
                users: userList,
            }]
        }
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
});

Mock.onPost('/api/auth/login').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const { email, password } = JSON.parse(config.data)
        const user = userList.find((u) => {
            return (u.email === email && u.password === password)
        })

        console.log(email, password)

        if (!user) {
            return [400, { message: 'Niepoprawny email lub hasło' }]
        }

        const accessToken = jwt.sign({ userId: user._id }, JWT_SECRET, {
            expiresIn: JWT_VALIDITY,
        })


        return [
            200,
            {
                accessToken,
                user: {
                    _id: user._id,
                    name: user.name,
                    surname: user.surname,
                    role: user.role,
                    admin: user.admin,
                    email: user.email,
                    age: user.age
                },
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/auth/register').reply((config) => {
    try {

        const { email, name, surname, password } = JSON.parse(config.data)


        const user = userList.find((u) => u.email === email)

        console.log(JSON.parse(config.data))

        const lastElement = userList.slice(-1);

        const newId = lastElement[0]._id + 1;


        if (user) {
            return [400, { message: 'User already exists!' }]
        }

        const newUser = {
            _id: newId,
            role: 'SA',
            name,
            surname,
            email,
            jobPosition: "Użytkownik",
            password,
            age: 25,
        }


        userList.push(newUser);
        console.log(newUser);
        console.log(userList);

        const accessToken = jwt.sign({ userId: newUser._id }, JWT_SECRET, {
            expiresIn: JWT_VALIDITY,
        })

        console.log(userList)

        return [
            200,
            {
                accessToken,
                user: { ...newUser }
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})



Mock.onGet('/api/auth/profile').reply((config) => {
    try {
        const { Authorization } = config.headers
        if (!Authorization) {
            return [401, { message: 'Invalid Authorization token' }]
        }

        const accessToken = Authorization.split(' ')[1]
        // const { userId } = jwt.verify(accessToken, JWT_SECRET)
        // const user = userList.find((u) => u._id === userId)
        const user = userList[0]


        if (!user) {
            return [401, { message: 'Invalid authorization token' }]
        }

        return [
            200,
            {
                user
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})
