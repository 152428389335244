import React from "react";
import { ThemeProvider } from "styled-components";

// theme.colors.primary: Główny kolor aplikacji.
// theme.colors.secondary: Drugorzędny kolor aplikacji.
// theme.colors.background: Kolor tła.
// theme.colors.text: Kolor tekstu.
// theme.colors.accent: Akcentowy kolor używany do wyróżnienia elementów.
// const errorColor = '#FF0000';
// const successColor = '#00FF00';
// const warningColor = '#FFA500';


const theme = {
  colors: {
    primaryColor: "#7d53de",
    secondaryColor: "#d0d02d",
    mainColor: "#FAFAFA",
    whiteColor: "#fff",
    headingColors: "#000",
    sidenavtextColor: "#475569",
  },
  fonts: ["Titillium Web", "sans-serif"],
  fontSizes: {
    small: "1rem",
    medium: "2rem",
    large: "4rem"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;