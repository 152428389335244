import React from 'react'
// import Logo from '../../../img/2020-07-22.png'
import logo from '../../../img/logo.svg'


const MatxLogo = () => {

    return (
        <img src={logo} height="50px" alt="logo"/>
    )
}

export default MatxLogo
