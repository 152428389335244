import axios from 'axios'

export const GET_PARAMS = 'GET_PARAMS'

export const getParams = () => (dispatch) => {
    axios.get('/api/parameters').then((res) => {
        dispatch({
            type: GET_PARAMS,
            payload: res.data,
        })
    })
}
