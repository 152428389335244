import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import RootReducer from './reducers/RootReducer'
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';

// let URL =  "http://10.0.0.7:8080"
let URL = "/db/"

var client = axios.create({ baseURL: process.env.NODE_ENV === 'development' ? URL : '/database/', responseType: 'json' });

const initialState = {}
const middlewares = [thunk, axiosMiddleware(client)]
let devtools = (x) => x

if (
    process &&
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

export const Store = createStore(
    RootReducer,
    initialState,
    compose(applyMiddleware(...middlewares), devtools)
)
